// @ is an alias to /src
import { MutationPayload } from 'vuex';
import { IVisitorContactsParams, IVisitor } from '@/models/visitor';
import store from '@/store';
import UserCard from '@/partials/UserCard.vue';
import Input from '@/components/form/input.vue';
import { IUserCard } from '@/partials';
import { getVisitorContacts } from '@/api/visitors';
import { getRocketUser, createRocketInstantMessage } from '@/api/chat';
import { computed, defineComponent, ref } from '@vue/composition-api';
import useContext from '@/composition/context';
import { filters } from '.';
export default defineComponent({
  components: {
    UserCard,
    Input,
  },
  data() {
    return {
      exhibitor: 0,
      event: 0,
      page: 1,
      searchTimeout: 0
    }
  },
  setup() {
    const { translations, contextEvent } = useContext();
    const visitors = computed(() => store.getters.getVisitors);
    const visitorsState = computed(() => store.getters.getVisitorsState);
    const visitorFilters = ref(filters());
    return {
      translations, visitors, contextEvent, visitorsState, visitorFilters
    };

  },
  created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    const userLoggedIn = store.getters.userLoggedIn;

    this.visitorFilters.search.onInput = this.search;

    if (userLoggedIn) {
      this.getVisitors();
    }
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setUserLogin') {
        this.getVisitors();
      }
      if (mutation.type === 'setLanguage') {
        this.visitorFilters = filters();
      }

      if (mutation.type === "setVisitors") {
        this.visitorFilters.search = {
          ...this.visitorFilters.search,
          loading: false
        }
      }
    });
  },
  methods: {
    search(value: string | number) {
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => {
        this.visitorFilters.search = {
          ...this.visitorFilters.search,
          loading: true,
          value
        }
        this.getVisitors(value as string);
      }, 1000);
    },
    openChat(user: number) {
      getRocketUser(`u${user}`).then((response) => {
        const { user } = response.data;
        createRocketInstantMessage(user.username).then((response) => {
          const roomId = response.data.room.rid;
          store.commit('setChatRoom', { rid: roomId, type: 'im', roomName: `${user.name}` });
          store.commit('setChatOpen');
        });
      })
        .catch((err) => {
          console.log(err);
          const errors = err.response.data;
          if (errors && errors.length > 0) {
            errors.forEach((error: string) => {
              store.commit('addPopup', {
                message: error,
                type: 'danger',
                autohide: true,
              });
            });
          } else {
            store.commit('addPopup', {
              message: err.nessage,
              type: 'danger',
              autohide: true,
            });
          }
        });
    },
    getVisitors(search: undefined | string = undefined) {
      const params: IVisitorContactsParams = {
        event: this.event,
        page_size: 40,
        exhibitor: this.exhibitor,
        page: this.page,
        search,
      };
      store.dispatch('fetchVisitorContacts', params);
    },
    next() {
      this.page = this.page + 1;
      const params: IVisitorContactsParams = {
        event: this.event,
        page_size: 40,
        exhibitor: this.exhibitor,
        page: this.page,
      };

      store.commit('setVisitorsLoading');

      getVisitorContacts(params).then((response) => {

        const visitors = {
          list: [...this.visitors, ...response.data.results],
          page: response.data.current,
          page_count: response.data.page_count,
        };

        store.commit('setVisitors', visitors);

      });
    },
    cardConfig(visitor: IVisitor) {
      const config: IUserCard = {
        name: visitor.name,
        detail: visitor.email,
        picture: visitor.profile_picture,
        route: `/event/${this.event}/manage-exhibitor/${this.exhibitor}/visitor/${visitor.id}}`,
      };
      return config;
    }
  }
});